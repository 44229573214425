import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import Axios from '../../utils/Axios';

export const fetchDeal = createAsyncThunk<
  Deal,
  { loanerId: string; dealId: string }
>(
  'dealdetails/fetchDeal',
  async ({ loanerId, dealId }: { loanerId: string; dealId: string }) => {
    try {
      const response = await Axios.get(
        `/deals/loan-officers/${loanerId}/deal/${dealId}/`,
      );
      return response.data;
    } catch (error) {
      return { error };
    }
  },
);

export const fetchUpdates = createAsyncThunk<UpdateResults, string>(
  'dealdetails/fetchUpdates',
  async (dealId: string, { getState }) => {
    try {
      const { dealdetails } = getState() as { dealdetails: DealDetailsState };
      const params: string[] = [];
      let queryPath = '';

      if (dealdetails.page) {
        params.push(`page=${dealdetails.page}`)
      }

      if (params.length) {
        queryPath += `?${params.join('&')}`;
      }
      const response = await Axios.get(
        `/deals/loan-officers/${dealId}/updates/${queryPath}`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      return { error };
    }
  },
);
