/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { submitContactForm } from './actions';
import * as Sentry from '@sentry/gatsby';

interface ContactUsState {
  openContactForm: boolean;
  loading: boolean;
  successfullySubmittedForm: boolean;
  error: any;
}

const initialState = {
  openContactForm: false,
  loading: false,
  successfullySubmittedForm: false,
  error: null,
} as ContactUsState;

const contactUsSlice = createSlice({
  name: 'contactus',
  initialState,
  reducers: {
    toggleOpenContactForm(state, action) {
      state.loading = false;
      state.successfullySubmittedForm = false;
      state.error = null;
      state.openContactForm = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitContactForm.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(submitContactForm.fulfilled, (state, { payload }) => {
      state.successfullySubmittedForm = true;
      state.error = false;
      state.loading = false;
    });
    builder.addCase(submitContactForm.rejected, (state, { error }) => {
      state.error = true;
      state.loading = false;
      Sentry.captureException(error);
    });
  },
});

export const { toggleOpenContactForm } = contactUsSlice.actions;

export default contactUsSlice.reducer;
