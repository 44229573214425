import { createBucketClient } from '@cosmicjs/sdk';
import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';

const bucket = createBucketClient({
  bucketSlug: process.env.COSMIC_SLUG || '',
  readKey: process.env.COSMIC_READ_KEY || '',
});

export const fetchResources = createAsyncThunk(
  'resources/fetchResources',
  async (id: any) => {
    try {
      const { objects } = await bucket.objects
        .find({
          type: 'resources',
          'metadata.id': id,
        })
        .props(['slug', 'title', 'content', 'metadata']);

      return { objects };
    } catch (error) {
      Sentry.captureException(error);
      return { error };
    }
  },
);
