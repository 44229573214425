import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import Axios from '../../utils/Axios';
import { ReportTypes } from '../../enums';

interface FetchReportByTypePayload {
  loanerId: string;
  type: ReportTypes;
}

export const fetchReportByType = createAsyncThunk<
  any,
  FetchReportByTypePayload
>(
  'reports/fetchReportByType',
  async ({ loanerId, type }: FetchReportByTypePayload) => {
    try {
      const response = await Axios.get(
        `/deals/loan-officers/${loanerId}/graphs/${type}/`,
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      const typedError: any = error;
      // get error status code
      const statusCode = typedError.response.status;
      if (statusCode === 500) {
        // display empty error message
        throw new Error('');
      }
      if (typeof typedError?.response?.data === 'string') {
        // set the message if its a string
        throw new Error(typedError.response.data);
      }

      throw new Error(''); // display empty error message
      
    }
  },
);
