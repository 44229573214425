/* eslint-disable no-param-reassign */
import * as Sentry from '@sentry/gatsby';
import { createSlice } from '@reduxjs/toolkit';
import { fetchResources } from './actions';

const initialState = {
  resources: [] as Array<Resource>,
  error: false as boolean,
  loading: false as boolean,
};

const resourcesSlice = createSlice({
  name: 'resources',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchResources.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchResources.fulfilled, (state, { payload }) => {
      state.resources = payload.objects;
      state.error = false;
      state.loading = false;
    });
    builder.addCase(fetchResources.rejected, (state, { error }) => {
      state.error = true;
      state.loading = false;
      Sentry.captureException(error);
    });
  },
});

export default resourcesSlice.reducer;
