import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import Axios from '../../utils/Axios';

interface SubmitContactFormProps {
  id: string;
  note: string;
  requested_response: string;
}

export const submitContactForm = createAsyncThunk(
  'contactus/submitContactForm',
  async (props: SubmitContactFormProps) => {
    const requestData = {
      note: props.note,
      requested_response: props.requested_response,
    };
    try {
      const response = await Axios.post(
        `/contacts/loan-officer/${props.id}/contact-request/`,
        {
          ...requestData,
        },
      );
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      return { error };
    }
  },
);
