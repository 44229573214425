enum NavTypes {
  TASKS,
  DASHBOARD,
  RESOURCES,
  DEALS,
  DEAL,
  TEAM_MANAGEMENT,
}

export default NavTypes;
