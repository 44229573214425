import * as Sentry from '@sentry/gatsby';

Sentry.init({
  dsn: 'https://89e7380b63514955bf2b1da68a1e3925@o385035.ingest.sentry.io/4504635456618496',
  sampleRate: 0.7,
  replaysSessionSampleRate: 0.1,
  replaysOnErrorSampleRate: 1.0,
  integrations: [
    new Sentry.BrowserTracing(),
    new Sentry.Replay({
      maskAllInputs: false,
      maskAllText: false,
      networkDetailAllowUrls: ['https://api.listwithclever.com/'],
      networkRequestHeaders: ['X-Custom-Header', 'Cache-Control'],
      networkResponseHeaders: ['X-Custom-Header', 'Referrer-Policy'],
    }),
  ],
  environment: process.env.NODE_ENV,
  enabled: process.env.NODE_ENV === 'production',
});
