enum ReportTypes {
  ActiveBuyerDeals = 'active-buyer-deals',
  Funnel = 'funnel',
  Leaderboard = 'leaderboard',
  UnattachedClosing = 'unattached-closing',
  MaxBuyerBudget = 'max-buyer-budget',
  OverallPerformance = 'overall-performance',
}

export default ReportTypes;
