/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchDeals } from './actions';
import * as Sentry from '@sentry/gatsby';

const initialState = {
  deals: [],
  activeFilters: {},
  error: null,
  filtersOpen: false,
  loading: false,
  searchQuery: '',
  singleDeal: null,
  singleDealError: null,
  singleDealLoading: false,
  sortBy: '',
  sortOrder: null,
  count: 0,
  page: 1,
  pageSize: 10,
} as DealsState;

const dealsSlice = createSlice({
  name: 'deals',
  initialState,
  reducers: {
    setActiveFilters(state, action) {
      state.activeFilters = action.payload;
    },
    setFiltersOpen(state, action) {
      state.filtersOpen = action.payload;
    },
    setSearchQuery(state, action) {
      state.searchQuery = action.payload;
    },
    setSortBy(state, action) {
      state.sortBy = action.payload;
    },
    setSortOrder(state, action) {
      state.sortOrder = action.payload;
    },
    setPage(state, action) {
      state.page = action.payload;
    },
    setPageSize(state, action) {
      state.pageSize = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeals.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchDeals.fulfilled, (state, { payload }) => {
      state.deals = payload.results;
      state.count = payload.count;
      state.error = false;
      state.loading = false;
    });
    builder.addCase(fetchDeals.rejected, (state, {error}) => {
      state.error = true;
      state.loading = false;
      Sentry.captureException(error);
    });
  },
});

export const {
  setActiveFilters,
  setFiltersOpen,
  setSearchQuery,
  setSortBy,
  setSortOrder,
  setPage,
  setPageSize,
} = dealsSlice.actions;

export default dealsSlice.reducer;
