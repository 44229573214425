enum DealStageTypes {
  BEING_PROCESSED = 'Customer Being Processed by Clever',
  CLEVER_IS_CALLING_CUSTOMER = 'Clever is Calling Customer',
  PREPARING_TO_MATCH_WITH_AGENTS = 'Preparing to Match with Agents',
  AGENT_INTRODUCED = 'Agent Introduced',
  COMMUNICATING_WITH_AGENT = 'Communicating with Agent',
  MET = 'Met',
  HOUSE_HUNTING = 'House Hunting',
  ON_MARKET = 'On Market',
  AGREEMENT_SIGNED = 'Agreement Signed',
  MAKING_OFFERS = 'Making Offers',
  UNDER_CONTRACT = 'Under Contract',
  CLOSED = 'Closed',
  NOT_USING_CLEVER_AGENT = 'Not Using Clever Agent',
  LOST_USING_ANOTHER_LENDER = 'Lost: Using Another Lender',
  LOST_UNABLE_TO_CONTACT = 'Lost: Unable to Contact',
  NEED_CORRECT_CONTACT_INFO = 'Need Correct Contact Info',
  UNKNOWN = 'Unknown',
}

const getFilterLabelByFilterType = (filter: DealStageTypes) => {
  switch (filter) {
    case DealStageTypes.HOUSE_HUNTING:
      return "House Hunting (Buyers Only)";
    case DealStageTypes.ON_MARKET:
      return "On Market (Sellers Only)";
    case DealStageTypes.MAKING_OFFERS:
      return "Making Offers (Buyers Only)";
    default:
      return filter;
  }
}

enum ConnectionStageTypes {
  LOST = 1,
  SEARCHING_FOR_AGENTS = 3,
  NOT_CONNECTED = 5,
  AGENT_REFERRED = 8,
  COMMUNICATING_PRE_APPOINTMENT = 9,
  COMMUNICATING_POST_APPOINTMENT = 10,
  LISTING_APPOINTMENT_SCHEDULED = 12,
  LISTING_AGREEMENT_SIGNED = 13,
  HOME_ON_MARKET = 14,
  PENDING = 15,
  AWAITING_PAYMENT = 16,
  UNDERPAID = 17,
  CLOSED = 18,
}

enum TransactionTypes {
  BUYER = 'buyer',
  SELLER = 'seller',
}

enum MaxBudgetTypes {
  UNDER_100K = '<$100k',
  BETWEEN_100K_250K = '$100k-$250k',
  BETWEEN_250K_500K = '$250k-$500k',
  BETWEEN_500K_750K = '$500k-$750k',
  BETWEEN_750K_1M = '$750k-$1M',
  OVER_1M = '>$1M',
}

export {
  DealStageTypes,
  ConnectionStageTypes,
  TransactionTypes,
  MaxBudgetTypes,
  getFilterLabelByFilterType,
};
