import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import Axios from '../../utils/Axios';

export const submitNewCustomerForm = createAsyncThunk(
  'newcustomer/submitNewCustomerForm',
  async (params: NewCustomerSubmitParams) => {
    try {
      const response = await Axios.post('/contacts/contact-ingress/', params);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      return { error };
    }
  },
);
