/* eslint-disable promise/always-return */
/* eslint-disable no-param-reassign */
/* eslint-disable promise/catch-or-return */
/* eslint-disable promise/no-return-wrap */
import axios from 'axios';
import { Auth } from '@aws-amplify/auth';
import { getLoginUrl } from '../getLoginUrl';

const baseURL = process.env.GATSBY_API_URL || 'http://localhost:8000';

const instance = axios.create({
  baseURL,
});

instance.interceptors.request.use(
  async (config): Promise<any> =>
    new Promise((resolve) => {
      Auth.currentSession()
        .then((session) => {
          const idTokenExpire = session.getAccessToken().getExpiration();
          const refreshToken = session.getRefreshToken();
          const currentTimeSeconds = Math.round(+new Date() / 1000);
          if (idTokenExpire < currentTimeSeconds) {
            Auth.currentAuthenticatedUser().then((res) => {
              res.refreshSession(
                refreshToken,
                (
                  err: any,
                  data: {
                    getAccessToken: () => {
                      (): any;
                      new (): any;
                      getJwtToken: { (): any; new (): any };
                    };
                  },
                ) => {
                  if (err) {
                    Auth.signOut().then(() => {
                      localStorage.removeItem('me');
                      window.location.href = getLoginUrl();
                    });
                    // Navigate to login gateway
                  } else {
                    config.headers.Authorization = `Bearer ${data
                      .getAccessToken()
                      .getJwtToken()}`;
                    resolve(config);
                  }
                },
              );
            });
          } else {
            config.headers.Authorization = `Bearer ${session
              .getAccessToken()
              .getJwtToken()}`;
            resolve(config);
          }
        })
        .catch(() => {
          // No logged-in user: don't set auth header
          // TODO: send them to the login gateway
          resolve(config);
        });
    }),
);

export default instance;
