/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { submitNewCustomerForm } from './actions';
import * as Sentry from '@sentry/gatsby';

interface ContactUsState {
  newCustomerModalOpen: boolean;
  newCustomerLoading: boolean;
  newCustomerSuccess: boolean;
  newCustomerError: boolean;
}

const initialState = {
  newCustomerModalOpen: false,
  newCustomerLoading: false,
  newCustomerSuccess: false,
  newCustomerError: false,
} as ContactUsState;

const newCustomerSlice = createSlice({
  name: 'newcustomer',
  initialState,
  reducers: {
    setNewCustomerModalOpen(state, action) {
      state.newCustomerError = false;
      state.newCustomerLoading = false;
      state.newCustomerSuccess = false;
      state.newCustomerModalOpen = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(submitNewCustomerForm.pending, (state) => {
      state.newCustomerLoading = true;
    });
    builder.addCase(submitNewCustomerForm.fulfilled, (state) => {
      state.newCustomerSuccess = true;
      state.newCustomerError = false;
      state.newCustomerLoading = false;
    });
    builder.addCase(submitNewCustomerForm.rejected, (state, {error}) => {
      state.newCustomerError = true;
      state.newCustomerLoading = false;
      Sentry.captureException(error);
    });
  },
});

export const { setNewCustomerModalOpen } = newCustomerSlice.actions;

export default newCustomerSlice.reducer;
