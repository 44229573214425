/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchLoanOfficer } from './actions';
import * as Sentry from '@sentry/gatsby';

interface LoanOfficerState {
  
  loanOfficer: LoanOfficer | null;
  loading: boolean;
  error: any;
}

const initialState = {
  loanOfficer: null,
  loading: false,
  error: null,
} as LoanOfficerState;

const loanOfficerSlice = createSlice({
  name: 'loanofficer',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchLoanOfficer.pending, (state) => {
      state.loading = true;
    });
    builder.addCase(fetchLoanOfficer.fulfilled, (state, { payload }) => {
      state.loanOfficer = payload;
      state.error = false;
      state.loading = false;
    });
    builder.addCase(fetchLoanOfficer.rejected, (state, { error }) => {
      state.error = true;
      state.loading = false;
      Sentry.captureException(error);
    });
  },
});

export default loanOfficerSlice.reducer;
