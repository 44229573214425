/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { fetchDeal, fetchUpdates } from './actions';
import * as Sentry from '@sentry/gatsby';

const initialState = {
  deal: null,
  updates: null,
  count: 0,
  page: 1
} as DealDetailsState;

const dealDetails = createSlice({
  name: 'dealdetails',
  initialState,
  reducers: {
    setUpdatesPage(state, action) {
      state.page = action.payload;
    },
    resetDeal(state) {
      state.deal = null;
    }
  },
  extraReducers: (builder) => {
    builder.addCase(fetchDeal.pending, (state) => {
      state.dealLoading = true;
    });
    builder.addCase(fetchDeal.fulfilled, (state, { payload }) => {
      state.deal = payload;
      state.dealError = false;
      state.dealLoading = false;
    });
    builder.addCase(fetchDeal.rejected, (state, {error}) => {
      state.dealError = true;
      state.dealLoading = false;
      Sentry.captureException(error);
    });
    builder.addCase(fetchUpdates.pending, (state) => {
      state.updatesLoading = true;
    });
    builder.addCase(fetchUpdates.fulfilled, (state, { payload }) => {
      state.updates = payload.results;
      state.count = payload.count;
      state.updatesError = false;
      state.updatesLoading = false;
    });
    builder.addCase(fetchUpdates.rejected, (state, {error}) => {
      state.updatesError = true;
      state.updatesLoading = false;
      Sentry.captureException(error);
    });
  },
});

export const { setUpdatesPage, resetDeal } = dealDetails.actions;

export default dealDetails.reducer;
