import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { fetchTasks } from './actions';
import { TasksTableColumns, TasksTabs } from '../../enums/TasksTypes';

export const initialState = {
  tasks: [],
  selectedTasks: [],
  activeTab: TasksTabs.NEEDS_ACTION,
  tasksError: null,
  tasksLoading: false,
  sortBy: '',
  sortOrder: null,
  count: 0,
  currentPage: 1,
  pageSize: 25,
} as TasksState;

const tasksSlice = createSlice({
  name: 'tasks',
  initialState,
  reducers: {
    removeTasksFromList(state, action: PayloadAction<number[]>) {
      state.tasks = state.tasks.filter(
        (task) => !action.payload.includes(task.id),
      );
    },
    toggleSelectedTask(state, action: PayloadAction<number>) {
      const index = state.selectedTasks.indexOf(action.payload);
      if (index === -1) {
        state.selectedTasks.push(action.payload);
      } else {
        state.selectedTasks.splice(index, 1);
      }
    },
    toggleAllTasks(state) {
      if (state.selectedTasks.length === state.tasks.length) {
        state.selectedTasks = [];
      } else {
        state.selectedTasks = state.tasks.map((task) => task.id);
      }
    },
    setActiveTab(state, action: PayloadAction<TasksTabs>) {
      state.activeTab = action.payload;
    },
    setSortBy(state, action: PayloadAction<TasksTableColumns>) {
      state.sortBy = action.payload;
    },
    setSortOrder(state, action: PayloadAction<'asc' | 'desc'>) {
      state.sortOrder = action.payload;
    },
    setCurrentPage(state, action: PayloadAction<number>) {
      state.currentPage = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchTasks.pending, (state) => {
      state.tasks = [];
      state.tasksError = false;
      state.tasksLoading = true;
    });
    builder.addCase(
      fetchTasks.fulfilled,
      (state, { payload }: PayloadAction<TasksResults>) => {
        state.tasks = payload.results;
        state.count = payload.count;
        state.tasksError = false;
        state.tasksLoading = false;
      },
    );
    builder.addCase(fetchTasks.rejected, (state) => {
      state.tasksError = true;
      state.tasksLoading = false;
    });
  },
});

export const {
  removeTasksFromList,
  toggleSelectedTask,
  toggleAllTasks,
  setActiveTab,
  setSortBy,
  setSortOrder,
  setCurrentPage,
} = tasksSlice.actions;

export default tasksSlice.reducer;
