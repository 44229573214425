import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import Axios from '../../utils/Axios';

export const fetchDeals = createAsyncThunk<DealResults, string>(
  'deals/fetchDeals',
  async (id: string, { getState }) => {
    try {
      const { deals } = getState() as { deals: DealsState };
      const params: string[] = [];
      let path = `/deals/loan-officers/${id}/`;

      // Search
      if (deals.searchQuery) {
        params.push(`string-filter=${encodeURIComponent(deals.searchQuery)}`);
      }

      // Filters
      if (deals.activeFilters) {
        Object.entries(deals.activeFilters as DealFilters).forEach(
          ([key, value]) => {
            value?.forEach((item: any) => {
              params.push(`${key}=${encodeURIComponent(item)}`);
            });
          },
        );
      }

      // Sorting
      if (deals.sortBy) {
        params.push(`sort=${encodeURIComponent(deals.sortBy)}`);
      }

      // Sorting
      if (deals.sortBy && deals.sortOrder) {
        params.push(`order=${deals.sortOrder}`);
      }

      if (deals.page) {
        params.push(`page=${deals.page}`);
      }

      if (deals.pageSize) {
        params.push(`page_size=${deals.pageSize}`);
      }

      if (params.length) {
        path += `?${params.join('&')}`;
      }

      const response = await Axios.get(path);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      return { error };
    }
  },
);
