import React from 'react';
import * as Sentry from '@sentry/gatsby';
import { ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Provider } from 'react-redux';
import store from '../../store';
// import cognito config to our provider
import '../../config/Cognito';

function LOReduxProvider({ element }) {
  return (
    <Sentry.ErrorBoundary showDialog>
      <Provider store={store}>
        {element}
        <ToastContainer />
      </Provider>
    </Sentry.ErrorBoundary>
  );
}

export default Sentry.withProfiler(LOReduxProvider);
