import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import Axios from '../../utils/Axios';

export const fetchLoanOfficers = createAsyncThunk<any, string>(
  'teammanagement/fetchLoanOfficers',
  async (loanerId: string, { getState, rejectWithValue }) => {
    if (!loanerId) {
      return rejectWithValue('Missing LO ID');
    }

    const { teammanagement } = getState() as {
      teammanagement: TeamManagementState;
    };

    try {
      const response = await Axios.get(
        `/v1/loan-officers/${loanerId}/loan-officers/${
          teammanagement?.loanOfficerSearchQuery
            ? `?search=${teammanagement.loanOfficerSearchQuery}`
            : ''
        }`,
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          store: 'TeamManagement',
          action: 'fetchLoanOfficers',
        },
      });

      return rejectWithValue(error);
    }
  },
);

export const submitLoanOfficer = createAsyncThunk(
  'teammanagement/submitLoanOfficer',
  async (
    { loanerId, params }: SubmitLoanOfficerParams,
    { rejectWithValue },
  ) => {
    if (!loanerId) {
      return rejectWithValue('Missing LO ID');
    }

    try {
      const response = await Axios.post(
        `/v1/loan-officers/${loanerId}/loan-officers/`,
        {
          ...params,
        },
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          store: 'TeamManagement',
          action: 'submitLoanOfficer',
        },
      });

      return rejectWithValue(error);
    }
  },
);

export const updateLoanOfficer = createAsyncThunk(
  'teammanagement/updateLoanOfficer',
  async (
    { submitterId, loanerId, params }: UpdateLoanOfficerParams,
    { rejectWithValue },
  ) => {
    if (!loanerId) {
      return rejectWithValue('Missing LO ID');
    }

    if (!submitterId) {
      return rejectWithValue('Missing submitter ID');
    }

    try {
      const response = await Axios.patch(
        `/v1/loan-officers/${submitterId}/loan-officers/${loanerId}/`,
        params,
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          store: 'TeamManagement',
          action: 'updateLoanOfficer',
        },
      });

      return rejectWithValue(error);
    }
  },
);

export const deleteLoanOfficer = createAsyncThunk(
  'teammanagement/deleteLoanOfficer',
  async (
    { loanerId, loanerToDelete }: DeleteLoanOfficerParams,
    { rejectWithValue },
  ) => {
    if (!loanerId) {
      return rejectWithValue('Missing LO ID fields');
    }

    if (!loanerToDelete) {
      return rejectWithValue('Missing loaner to delete ID');
    }

    try {
      const response = await Axios.delete(
        `/v1/loan-officers/${loanerId}/loan-officers/${loanerToDelete}/`,
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          store: 'TeamManagement',
          action: 'deleteLoanOfficer',
        },
      });

      return rejectWithValue(error);
    }
  },
);

export const fetchTeams = createAsyncThunk<Team[], string>(
  'teammanagement/fetchTeams',
  async (loanerId: string, { getState, rejectWithValue }) => {
    if (!loanerId) {
      return rejectWithValue('Missing LO ID fields');
    }

    const { teammanagement } = getState() as {
      teammanagement: TeamManagementState;
    };

    try {
      const response = await Axios.get(
        `/v1/loan-officers/${loanerId}/teams/${
          teammanagement?.teamSearchQuery
            ? `?search=${teammanagement.teamSearchQuery}`
            : ''
        }`,
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          store: 'TeamManagement',
          action: 'fetchTeams',
        },
      });

      return rejectWithValue(error);
    }
  },
);

export const submitTeam = createAsyncThunk(
  'teammanagement/submitTeam',
  async ({ loanerId, teamName }: NewTeamSubmitParams, { rejectWithValue }) => {
    if (!loanerId) {
      return rejectWithValue('Missing LO ID');
    }

    if (!teamName) {
      return rejectWithValue('Missing team name');
    }

    try {
      const response = await Axios.post(
        `/v1/loan-officers/${loanerId}/teams/`,
        {
          name: teamName,
        },
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          store: 'TeamManagement',
          action: 'submitTeam',
        },
      });

      return rejectWithValue(error);
    }
  },
);

export const updateTeam = createAsyncThunk(
  'teammanagement/updateTeam',
  async (
    { loanerId, teamId, teamName }: UpdateTeamParams,
    { rejectWithValue },
  ) => {
    if (!loanerId) {
      return rejectWithValue('Missing LO ID fields');
    }

    if (!teamName) {
      return rejectWithValue('Missing team name');
    }

    try {
      const response = await Axios.patch(
        `/v1/loan-officers/${loanerId}/teams/${teamId}/`,
        {
          name: teamName,
        },
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          store: 'TeamManagement',
          action: 'updateTeam',
        },
      });

      return rejectWithValue(error);
    }
  },
);

export const deleteTeam = createAsyncThunk(
  'teammanagement/deleteTeam',
  async ({ loanerId, teamId }: DeleteTeamParams, { rejectWithValue }) => {
    if (!loanerId) {
      return rejectWithValue('Missing LO ID fields');
    }

    if (!teamId) {
      return rejectWithValue('Missing team ID');
    }

    try {
      const response = await Axios.delete(
        `/v1/loan-officers/${loanerId}/teams/${teamId}/`,
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          store: 'TeamManagement',
          action: 'deleteTeam',
        },
      });

      return rejectWithValue(error);
    }
  },
);

export const reassignDeals = createAsyncThunk(
  'teammanagement/reassignDeals',
  async (
    { loanerId, reassignedFrom, reassignedTo }: ReassignDealsParams,
    { rejectWithValue },
  ) => {
    if (!loanerId) {
      return rejectWithValue('Missing LO ID fields');
    }

    if (!reassignedFrom) {
      return rejectWithValue('Missing reassigned from ID');
    }

    if (!reassignedTo) {
      return rejectWithValue('Missing reassigned to ID');
    }

    try {
      const response = await Axios.post(
        `/v1/loan-officers/${loanerId}/loan-officers/${reassignedFrom}/reassign-deals/`,
        {
          id: reassignedFrom,
          new_loan_officer_id: reassignedTo,
        },
      );

      return response.data;
    } catch (error) {
      Sentry.captureException(error, {
        tags: {
          store: 'TeamManagement',
          action: 'reaassignDeals',
        },
      });

      return rejectWithValue(error);
    }
  },
);
