/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import {
  fetchLoanOfficers,
  fetchTeams,
  submitLoanOfficer,
  submitTeam,
  deleteLoanOfficer,
  reassignDeals,
  updateTeam,
  deleteTeam,
  updateLoanOfficer,
} from './actions';

const initialState = {
  // Loan Officers Data
  loanOfficers: [],
  loanOfficersLoading: false,
  loanOfficersError: false,

  // Teams Data
  teams: [],
  teamsLoading: false,
  teamsError: false,

  // Create Loan Officer Modal
  newLoanOfficerModalOpen: false,
  newLoanOfficerLoading: false,
  newLoanOfficerSuccess: false,
  newLoanOfficerError: false,

  // Update Loan Officer Modal
  updateLoanOfficerLoading: false,
  updateLoanOfficerSuccess: false,
  updateLoanOfficerError: false,

  // Delete Loan Officer Modal
  deleteLoanOfficerLoading: false,
  deleteLoanOfficerSuccess: false,
  deleteLoanOfficerError: false,

  // Reassign Deals
  reassignDealsLoading: false,
  reassignDealsSuccess: false,
  reassignDealsError: false,

  // Create Team Modal
  newTeamModalOpen: false,
  newTeamLoading: false,
  newTeamSuccess: false,
  newTeamError: false,

  // Update Team Modal
  updateTeamLoading: false,
  updateTeamSuccess: false,
  updateTeamError: false,

  // Delete Team Modal
  deleteTeamLoading: false,
  deleteTeamSuccess: false,
  deleteTeamError: false,

  // Search filters/queries
  loanOfficerSearchQuery: '',
  teamSearchQuery: '',
  selectedTeam: undefined,
} as TeamManagementState;

const teamManagementSlice = createSlice({
  name: 'teammanagement',
  initialState,
  reducers: {
    setNewLoanOfficerModalOpen(state, action) {
      state.newLoanOfficerError = false;
      state.newLoanOfficerLoading = false;
      state.newLoanOfficerSuccess = false;
      state.newLoanOfficerModalOpen = action.payload;
    },
    resetUpdateLoanOfficerModal(state) {
      state.updateLoanOfficerError = false;
      state.updateLoanOfficerLoading = false;
      state.updateLoanOfficerSuccess = false;
      state.reassignDealsError = false;
      state.reassignDealsLoading = false;
      state.reassignDealsSuccess = false;
      state.deleteLoanOfficerError = false;
      state.deleteLoanOfficerLoading = false;
      state.deleteLoanOfficerSuccess = false;
    },
    setNewTeamModalOpen(state, action) {
      state.newTeamError = false;
      state.newTeamLoading = false;
      state.newTeamSuccess = false;
      state.newTeamModalOpen = action.payload;
    },
    resetEditTeamModal(state) {
      state.updateTeamError = false;
      state.updateTeamLoading = false;
      state.updateTeamSuccess = false;
      state.deleteTeamError = false;
      state.deleteTeamLoading = false;
      state.deleteTeamSuccess = false;
    },
    setLoanOfficerSearchQuery(state, action) {
      state.loanOfficerSearchQuery = action.payload;
    },
    setTeamSearchQuery(state, action) {
      state.teamSearchQuery = action.payload;
    },
    setSelectedTeam(state, action) {
      state.selectedTeam = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(fetchLoanOfficers.pending, (state) => {
      state.loanOfficersLoading = true;
    });
    builder.addCase(fetchLoanOfficers.fulfilled, (state, { payload }) => {
      state.loanOfficers = payload.results;
      state.loanOfficersError = false;
      state.loanOfficersLoading = false;
    });
    builder.addCase(fetchLoanOfficers.rejected, (state) => {
      state.loanOfficersError = true;
      state.loanOfficersLoading = false;
    });
    builder.addCase(fetchTeams.pending, (state) => {
      state.teamsLoading = true;
    });
    builder.addCase(fetchTeams.fulfilled, (state, { payload }) => {
      state.teams = payload;
      state.teamsError = false;
      state.teamsLoading = false;
    });
    builder.addCase(fetchTeams.rejected, (state) => {
      state.teamsError = true;
      state.teamsLoading = false;
    });
    builder.addCase(submitLoanOfficer.pending, (state) => {
      state.newLoanOfficerLoading = true;
    });
    builder.addCase(submitLoanOfficer.fulfilled, (state) => {
      state.newLoanOfficerError = false;
      state.newLoanOfficerLoading = false;
      state.newLoanOfficerSuccess = true;
    });
    builder.addCase(submitLoanOfficer.rejected, (state) => {
      state.newLoanOfficerError = true;
      state.newLoanOfficerLoading = false;
    });
    builder.addCase(updateLoanOfficer.pending, (state) => {
      state.updateLoanOfficerLoading = true;
    });
    builder.addCase(updateLoanOfficer.fulfilled, (state) => {
      state.updateLoanOfficerSuccess = true;
      state.updateLoanOfficerError = false;
      state.updateLoanOfficerLoading = false;
    });
    builder.addCase(updateLoanOfficer.rejected, (state) => {
      state.updateLoanOfficerError = true;
      state.updateLoanOfficerLoading = false;
    });
    builder.addCase(deleteLoanOfficer.pending, (state) => {
      state.deleteLoanOfficerLoading = true;
    });
    builder.addCase(deleteLoanOfficer.fulfilled, (state) => {
      state.deleteLoanOfficerError = false;
      state.deleteLoanOfficerLoading = false;
      state.deleteLoanOfficerSuccess = true;
    });
    builder.addCase(deleteLoanOfficer.rejected, (state) => {
      state.deleteLoanOfficerError = true;
      state.deleteLoanOfficerLoading = false;
    });
    builder.addCase(reassignDeals.pending, (state) => {
      state.reassignDealsLoading = true;
    });
    builder.addCase(reassignDeals.fulfilled, (state) => {
      state.reassignDealsError = false;
      state.reassignDealsLoading = false;
      state.reassignDealsSuccess = true;
    });
    builder.addCase(reassignDeals.rejected, (state) => {
      state.reassignDealsError = true;
      state.reassignDealsLoading = false;
    });
    builder.addCase(submitTeam.pending, (state) => {
      state.newTeamLoading = true;
    });
    builder.addCase(submitTeam.fulfilled, (state) => {
      state.newTeamSuccess = true;
      state.newTeamError = false;
      state.newTeamLoading = false;
    });
    builder.addCase(submitTeam.rejected, (state) => {
      state.newTeamError = true;
      state.newTeamLoading = false;
    });
    builder.addCase(updateTeam.pending, (state) => {
      state.updateTeamLoading = true;
    });
    builder.addCase(updateTeam.fulfilled, (state) => {
      state.updateTeamSuccess = true;
      state.updateTeamError = false;
      state.updateTeamLoading = false;
    });
    builder.addCase(updateTeam.rejected, (state) => {
      state.updateTeamError = true;
      state.updateTeamLoading = false;
    });
    builder.addCase(deleteTeam.pending, (state) => {
      state.deleteTeamLoading = true;
    });
    builder.addCase(deleteTeam.fulfilled, (state) => {
      state.deleteTeamSuccess = true;
      state.deleteTeamError = false;
      state.deleteTeamLoading = false;
    });
    builder.addCase(deleteTeam.rejected, (state) => {
      state.deleteTeamError = true;
      state.deleteTeamLoading = false;
    });
  },
});

export const {
  setNewLoanOfficerModalOpen,
  resetUpdateLoanOfficerModal,
  setNewTeamModalOpen,
  resetEditTeamModal,
  setLoanOfficerSearchQuery,
  setTeamSearchQuery,
  setSelectedTeam,
} = teamManagementSlice.actions;

export default teamManagementSlice.reducer;
