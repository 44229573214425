export enum TasksTableColumns {
  TITLE = 'Task Title',
  TYPE = 'Task Type',
  CREATE_DATE = 'Create Date',
  DETAILS = 'Details',
  MORE = 'More',
}

export enum TasksTabs {
  NEEDS_ACTION = 'Needs Action',
  COMPLETED = 'Completed',
}
