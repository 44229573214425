import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import Axios from '../../utils/Axios';

export const fetchLoanOfficer = createAsyncThunk<LoanOfficer, string>(
  'loanofficer/fetchLoanOfficer',
  async (id: string) => {
    try {
      const response = await Axios.get(`/contacts/loan-officer/${id}/`);
      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      return { error };
    }
  },
);
