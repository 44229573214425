import { createAsyncThunk } from '@reduxjs/toolkit';
import * as Sentry from '@sentry/gatsby';
import Axios from '../../utils/Axios';
import { TasksTabs } from '../../enums/TasksTypes';

export const fetchTasks = createAsyncThunk<TasksResults, { loanerId: string }>(
  'tasks/fetchTasks',
  async ({ loanerId }, { getState, rejectWithValue }) => {
    try {
      const { tasks } = getState() as { tasks: TasksState };
      let path = `/deals/loan-officers/${loanerId}/tasks/`;
      const params: string[] = [];

      // Sorting
      if (tasks.sortBy) {
        params.push(`sort=${encodeURIComponent(tasks.sortBy)}`);
      }

      // Sort order
      if (tasks.sortOrder) {
        params.push(`sort_order=${encodeURIComponent(tasks.sortOrder)}`);
      }

      // Page
      if (tasks.currentPage) {
        params.push(`page=${tasks.currentPage}`);
      }

      // Page Size
      if (tasks.pageSize) {
        params.push(`page_size=${tasks.pageSize}`);
      }

      if (tasks.activeTab === TasksTabs.COMPLETED) {
        params.push('is_completed=1');
      }

      if (params.length) {
        path += `?${params.join('&')}`;
      }

      const response = await Axios.get(path);

      return response.data;
    } catch (error) {
      Sentry.captureException(error);
      return rejectWithValue(error);
    }
  },
);

export const markTasksAsCompleted = createAsyncThunk<
  TasksResults,
  { loanerId: string; taskIDs: number[] }
>('tasks/markTasksAsCompleted', async ({ loanerId, taskIDs = [] }) => {
  try {
    let path = `/deals/loan-officers/${loanerId}/tasks/`;

    const response = await Axios.put(path, {
      ids: taskIDs,
    });

    return response.data;
  } catch (error) {
    Sentry.captureException(error);
    return { error };
  }
});
