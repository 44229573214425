/* eslint-disable no-param-reassign */
import { createSlice } from '@reduxjs/toolkit';
import { ReportTypes } from '../../enums';
import { fetchReportByType } from './actions';
import * as Sentry from '@sentry/gatsby';

const initialState = {
  [ReportTypes.ActiveBuyerDeals]: {
    loading: false,
    error: false,
    message: '',
    report: null,
  },
  [ReportTypes.Funnel]: {
    loading: false,
    error: false,
    message: '',
    report: null,
  },
  [ReportTypes.Leaderboard]: {
    loading: false,
    error: false,
    message: '',
    report: null,
  },
  [ReportTypes.UnattachedClosing]: {
    loading: false,
    error: false,
    message: '',
    report: null,
  },
  [ReportTypes.MaxBuyerBudget]: {
    loading: false,
    error: false,
    message: '',
    report: null,
  },
  [ReportTypes.OverallPerformance]: {
    loading: false,
    error: false,
    message: '',
    report: null,
  },
};

const reportsSlice = createSlice({
  name: 'reports',
  initialState,
  reducers: {},
  extraReducers: (builder) => {
    builder.addCase(fetchReportByType.pending, (state, { meta }) => {
      const { type } = meta.arg;
      state[type].loading = true;
    });
    builder.addCase(fetchReportByType.fulfilled, (state, { payload, meta }) => {
      const { type } = meta.arg;
      state[type].loading = false;
      state[type].report = payload;
    });
    builder.addCase(fetchReportByType.rejected, (state, { meta, error }) => {
      const { type } = meta.arg;
      state[type].loading = false;
      state[type].error = true;
      state[type].message = error?.message || '';
      Sentry.captureException(error);
    });
  },
});

export default reportsSlice.reducer;
